<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ instance.company_types_uuid.type }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instance.name }}
          </span>
        </div>
      </div>
    </div>

    <v-card>
      <DataTablePagination
        :page="page"
        :total-items="totalItems"
        :headers="headers"
        :items="transactions"
        :total-pages.sync="totalPages"
        :is-loading="isLoadingPackage"
        :no-add-button="noAddButton"
        :no-search="noSearch"
        :filter-library="filterLibrary"
        :library-types="types"
        title="Perpustakaan"
        subtitle="Daftar buku ditampilkan per tanggal pembelian"
        @change-page="paginationHandler"
        @open-detail="openDetail"
        @filter-purchase="handleFilter"
      >
      </DataTablePagination>
    </v-card>

    <library-detail
      :detail-dialog="detailDialog"
      :package-uuid="packageUuid"
      @close-dialog="handleCloseDialog"
    ></library-detail>
  </div>
</template>

<script>
import LibraryDetail from '@/views/library/LibraryDetail.vue'
import { mdiClose, mdiDotsVertical, mdiTownHall } from '@mdi/js'
import DataTablePagination from '../components/DataTablePagination.vue'

export default {
  name: 'Library',
  components: {
    DataTablePagination,
    LibraryDetail,
  },
  data() {
    return {
      dialog: '',
      search: '',
      confirmDialog: false,
      confirmCancels: false,
      validateDelete: null,
      isLoadingPackage: true,
      isLoadingInstance: false,
      isLoadingButton: false,
      addFormDialog: false,
      editFormDialog: false,
      confirmDeleteDialog: false,
      noAddButton: true,
      noSearch: true,
      filterLibrary: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 10,
      detailDialog: false,
      icons: {
        mdiDotsVertical,
        mdiTownHall,
        mdiClose,
      },
      headers: [
        { text: 'No.', value: 'index' },
        { text: 'Tanggal Pembelian', value: 'created_at' },
        // { text: 'Tipe Buku', value: 'type' },
        { text: 'Paket', value: 'package' },
        { text: 'Jumlah', value: 'item_count' },
        { text: 'Harga', value: 'price' },
        { text: '', value: 'openDetail' },
      ],

      transactions: [],
      types: [
        {
          id: 1,
          name: 'Audio',
        },
        {
          id: 2,
          name: 'Video',
        },
        {
          id: 2,
          name: 'Ebook',
        },
      ],
      instance: {},
      serviceTransactionCompany: 'transactioncompany',
      serviceType: 'instancesetting',
      serviceInstance: 'instance',
      packageUuid: '',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
    }
  },
  created() {
    this.getDetailInstance()
    this.getTransactionInstance()
  },
  methods: {
    handleFilter(date) {
      if (!date) this.getTransactionInstance()
      else this.getTransactionInstance({ date })
    },

    async getDetailInstance() {
      this.isLoadingInstance = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.isLoadingInstance = false
        },
        err => {
          console.error(err)
          this.isLoadingInstance = false
        },
      )

      this.isLoadingInstance = false
    },

    async getTransactionInstance(params = {}) {
      this.isLoadingPackage = true

      await this.$services.ApiServices.list(this.serviceTransactionCompany, {
        ...params,
        company_uuid: this.instanceUuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.transactions = data.data.map((data, index) => ({
            ...data,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
          this.isLoadingPackage = false
        },
        err => {
          console.error(err)
          this.isLoadingPackage = false
        },
      )

      this.isLoadingPackage = false
    },

    openDetail(uuid) {
      this.packageUuid = uuid
      this.detailDialog = true
    },

    handleCloseDialog() {
      this.packageUuid = ''
      this.detailDialog = false
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.book-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}

.search-book > .v-input__control > .v-text-field__details {
  display: none;
}

.search-book > .v-input__control > .v-input__slot {
  margin: 0 !important;
}
</style>
